import React, { ReactElement } from 'react';
import {
  Heading as ChakraHeading,
  HeadingProps as ChakraHeadingProps,
  useBreakpointValue,
  VisuallyHidden,
} from '@chakra-ui/react';
import { isEmptyNode } from '../../utils/other';
import { Hyphenate } from '../Hyphenate';
import { Link } from '../Link';
import { HeadingLevel, HEADING_SIZES, HEADING_HYPHENATION } from './misc';

export type HeadingProps = {
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'div';
  level?: HeadingLevel;
} & Omit<ChakraHeadingProps, 'as'>;

export const Heading = ({
  children,
  as,
  id,
  level = 2,
  color = 'tealCarnation',
  sx,
  ...rest
}: HeadingProps): ReactElement | null => {
  const minWordLength = useBreakpointValue(HEADING_HYPHENATION[level]);

  return isEmptyNode(children) ? null : (
    <ChakraHeading
      id={id}
      as={as || `h${level}`}
      color={color}
      fontSize={HEADING_SIZES[level]}
      fontWeight={500}
      marginTop={'1em'}
      marginBottom={'0.3em'}
      sx={{
        '& a:not([href^="#"])': {
          color: color,
          textDecoration: 'underline',
        },
        '& a:not([href^="#"]):hover': { textDecoration: 'none' },
        '& code': { fontWeight: 500 },
        '& strong': { fontWeight: 900 },
        '& .headingAnchor': { opacity: 0 },
        '&:hover': { '& .headingAnchor': { opacity: 0.5 } },
        '& .headingAnchor:hover, & .headingAnchor:focus, & .headingAnchor:active':
          { opacity: 1 },
        ...sx,
      }}
      {...rest}
    >
      {!id ? (
        <Hyphenate minWordLength={minWordLength}>{children}</Hyphenate>
      ) : (
        <>
          <span aria-hidden>
            <Hyphenate minWordLength={minWordLength}>{children}</Hyphenate>
          </span>
          <Link
            className={'headingAnchor'}
            href={`#${id}`}
            marginX={'0.2em'}
            paddingX={'0.2em'}
          >
            <span aria-hidden>#</span>
            <VisuallyHidden>{children}</VisuallyHidden>
          </Link>
        </>
      )}
    </ChakraHeading>
  );
};
