export const HEADING_LEVELS = [1, 2, 3, 4, 5, 6] as const;

export type HeadingLevel = typeof HEADING_LEVELS[number];

export const HEADING_SIZES = {
  1: {
    base: 50,
    s: `calc(${50}px + ${100 - 50} * (100vw - ${600}px) / ${1200 - 600})`,
    l: 100,
  },
  2: {
    base: 43,
    s: `calc(${43}px + ${77 - 43} * (100vw - ${600}px) / ${1200 - 600})`,
    l: 77,
  },
  3: {
    base: 37,
    s: `calc(${37}px + ${60 - 37} * (100vw - ${600}px) / ${1200 - 600})`,
    l: 60,
  },
  4: {
    base: 32,
    s: `calc(${32}px + ${47 - 32} * (100vw - ${600}px) / ${1200 - 600})`,
    l: 47,
  },
  5: {
    base: 28,
    s: `calc(${28}px + ${36 - 28} * (100vw - ${600}px) / ${1200 - 600})`,
    l: 36,
  },
  6: {
    base: 25,
    s: `calc(${25}px + ${29 - 25} * (100vw - ${600}px) / ${1200 - 600})`,
    l: 29,
  },
};

export const HEADING_HYPHENATION = {
  1: {
    base: 5,
    s: 7,
    l: 9,
  },
  2: {
    base: 6,
    s: 9,
    l: 11,
  },
  3: {
    base: 7,
    s: 12,
    l: 14,
  },
  4: {
    base: 9,
    s: 15,
    l: 18,
  },
  5: {
    base: 11,
    s: 19,
    l: 23,
  },
  6: {
    base: 13,
    s: 23,
    l: 29,
  },
};
