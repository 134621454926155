import React, { Children, ReactElement, ReactNode } from 'react';
import { Box } from '@chakra-ui/react';
import { hyphenateSync as hyphenateEn } from 'hyphen/en';
import { hyphenateSync as hyphenateFi } from 'hyphen/fi';
import { useLocale } from '../../utils/hooks';
import { SOFT_HYPHEN } from '../../utils/string';
import { Locale } from '../../utils/types';

export type HyphenateProps = {
  children: ReactNode;
  componentLocale?: Locale;
  hyphenChar?: string;
  minWordLength?: number;
};

export const Hyphenate = ({
  children,
  componentLocale,
  hyphenChar = SOFT_HYPHEN,
  minWordLength = 10,
}: HyphenateProps): ReactElement => {
  const { locale, lang } = useLocale(componentLocale);
  const hyphenate = locale === 'fi' ? hyphenateFi : hyphenateEn;
  const regex = new RegExp(
    `(?=[\\w\\p{Letter}\\p{Mark}]{${
      minWordLength - 1
    },})(?=\\B)([\\w\\p{Letter}\\p{Mark}])\\1{${Math.ceil(
      (minWordLength - 1) / 2
    )},}(?=\\B)`,
    'gu'
  );

  return (
    <>
      {Children.toArray(children).map((child, i) =>
        typeof child == 'string' ? (
          <Box
            key={child + i}
            lang={lang}
            as={'span'}
            sx={{ hyphens: 'manual' }}
          >
            {hyphenate(
              child.replace(regex, (match) => match.split('').join(hyphenChar)),
              { hyphenChar, minWordLength }
            )}
          </Box>
        ) : (
          <Box
            key={child.toString() + i}
            lang={lang}
            as={'span'}
            sx={{ hyphens: 'auto' }}
          >
            {child}
          </Box>
        )
      )}
    </>
  );
};
